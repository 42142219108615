body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --bg-accent: #484a4d;
  --text-color: white;
  --nav-size: 70px;
  --border: 2px solid #474a4d;
  --border-radius: 20px;
  --speed: 500ms;
  --bg: #484a4d;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 10px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

/* Dropdown Menu */

.responsive-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown {
  position: relative;
  width: 100%;
  max-width: 300px; /* Adjust the max-width as needed */
  margin-top: 10px;
  border: var(--border);
  border-radius: var(--border-radius);
  transition: height var(--speed) ease;
  padding: 5px;
  overflow: hidden;
  background: var(--bg);
  display: inline-block; /* Add this line */
}

.menu {
  width: 100%;
  overflow: hidden;
}

.menu-item {
  height: 60px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  font-weight: bold;
  color: white;
  font-size: 16px; /* Adjust the font size as needed */
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes */
.menu-primary-enter {
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(100%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit {
  position: absolute;
}

.menu-secondary-exit-active {
  transform: translateX(100%);
  transition: all var(--speed) ease;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
  .dropdown {
    max-width: 100%; /* Adjust the max-width as needed */
  }
}

@media screen and (max-width: 480px) {
  .menu-item {
    font-size: 12px; /* Adjust the font size as needed */
  }
}